import axios from 'axios';
import { ApiResponse, IsQuoteAudioListened, QuoteAudioIdentifier } from '../../../ts';

export const getIsQuoteAudioAsListenedService = async ({
  quoteId,
  audioId,
}: QuoteAudioIdentifier) => {
  const path = `/patients/get-is-quote-audio-listened?quoteId=${quoteId}&audioId=${audioId}`;

  const response = await axios.get<ApiResponse<IsQuoteAudioListened>>(path);

  const { data } = response;

  if (data.isOk !== true) {
    throw new Error('Error getting is audio listened');
  }

  return data.data;
};
