import { PopupOptionsSetter } from 'src/ts';
import { usePopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useShowErrorPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = usePopup();
  const navigate = useNavigate();

  const errorPopupOptions = (bodyText: string, navigateTo?: string): PopupOptionsSetter => {
    const onClose = () => {
      navigateTo && navigate(navigateTo);
    };

    const children = bodyText
      .split('|||')
      .map(text => <p key={text}>{GlobalService.uppercaseAfterPointFirstsLetters(t(text))}</p>);

    return {
      title: GlobalService.uppercaseAfterPointFirstsLetters(t('error')),
      children,
      onClose,
      disableClickOutside: true,
      buttons: [
        {
          styleType: 'primary',
          children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
          onClick: () => {
            closePopup();
            onClose();
          },
        },
      ],
    };
  };

  const showErrorPopup = (bodyText: string, navigateTo?: string) => {
    openPopup(errorPopupOptions(bodyText, navigateTo));
  };

  return { showErrorPopup };
};
